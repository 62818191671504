import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useState, useEffect, ChangeEvent } from 'react';
import { writeContract } from '@wagmi/core';
import { useAccount, useSwitchChain } from 'wagmi';
import { validatedDecimalStr } from '@/utils/value-format';
import { COLLATERAL_DECIMALS } from '@/store/use-markets-store';
import { wagmiConfig } from '@/lib/wagmi/config';
import USDC_ABI from '@/lib/abis/mock-usdc-abi';
import { getUSDCAddress } from '@/utils/addresses';
import { zksyncSepoliaTestnet } from 'wagmi/chains';

interface MintingDialogProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  displayTrigger?: boolean;
}

const MAX_AMOUNT = 10_000;

export const MintingDialog = ({
  isOpen: externalIsOpen,
  onOpenChange = () => {},
  displayTrigger = true,
}: MintingDialogProps) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isControlled = externalIsOpen !== undefined;
  const isOpen = isControlled ? externalIsOpen : internalIsOpen;

  useEffect(() => {
    if (isControlled) {
      setInternalIsOpen(externalIsOpen);
    }
  }, [externalIsOpen, isControlled]);

  const handleOpenChange = (newIsOpen: boolean) => {
    if (!isControlled) {
      setInternalIsOpen(newIsOpen);
    }
    onOpenChange?.(newIsOpen);
  };

  const { switchChain } = useSwitchChain();
  const { address, chainId } = useAccount();

  const [isProcessing, setIsProcessing] = useState(false);
  const [amount, setAmount] = useState<string>('');
  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amount = validatedDecimalStr(
      e.target.value,
      Number(COLLATERAL_DECIMALS),
      10,
    );
    if (Number(amount) > MAX_AMOUNT) {
      return;
    }

    setAmount(amount);
  };

  const handleMint = async () => {
    if (!amount || !chainId) return;

    if (chainId !== zksyncSepoliaTestnet.id) {
      switchChain({ chainId: zksyncSepoliaTestnet.id });
    }

    setIsProcessing(true);
    try {
      const tokens = BigInt(amount) * 10n ** COLLATERAL_DECIMALS;
      await writeContract(wagmiConfig, {
        abi: USDC_ABI,
        address: getUSDCAddress(chainId),
        functionName: 'publicMint',
        args: [tokens],
      });
      handleOpenChange(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (!open) return;

    setAmount('');
  }, [open]);

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      {displayTrigger && (
        <DialogTrigger asChild>
          <Button className="rounded-none font-mono text-sm font-normal uppercase tracking-wider">
            MINT
          </Button>
        </DialogTrigger>
      )}
      <DialogContent className="gap-0 border border-border bg-background p-0 sm:max-w-md sm:rounded-none">
        <DialogHeader className="px-5 pt-6">
          <DialogTitle className="text-xl text-foreground">
            Mint tokens
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-6 px-5 pb-10 pt-6">
          <p className="leading-relaxed text-vestgrey-100">
            (Only available on Testnet) You should be on zkSync Sepolia and have
            some testnet ETH. Refresh to see your new balance.
          </p>

          <div className="my-3 flex flex-col gap-3">
            <Label
              htmlFor="amount"
              className="text-left text-base text-vestgrey-100"
            >
              Amount
            </Label>
            <div className="grid grid-cols-[1fr_max-content_max-content] items-center gap-3">
              <Input
                id="size"
                className="rounded-none bg-vestgrey-800 font-mono text-base"
                type="text"
                value={amount}
                onChange={handleAmountChange}
              />
              <svg
                width="22"
                height="22"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_34_356)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.98108 0.5C15.4935 0.5 19.9622 4.96867 19.9622 10.4811C19.9622 15.9935 15.4935 20.4622 9.98108 20.4622C4.46867 20.4622 0 15.9935 0 10.4811C0 4.96867 4.46867 0.5 9.98108 0.5Z"
                    fill="#2775CA"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2615 3.45891C12.0364 3.38701 11.8524 3.5206 11.8524 3.7569V4.33803C11.8524 4.49647 11.9718 4.6768 12.1206 4.73132C14.5096 5.60633 16.2191 7.90261 16.2191 10.591C16.2191 13.2794 14.5096 15.5756 12.1206 16.4506C11.9574 16.5104 11.8524 16.6702 11.8524 16.844V17.4251C11.8524 17.6613 12.0364 17.795 12.2615 17.723C15.2806 16.7584 17.4667 13.93 17.4667 10.591C17.4667 7.25196 15.2806 4.42352 12.2615 3.45891ZM8.10949 3.7569C8.10949 3.5206 7.92545 3.38701 7.70039 3.45891C4.68129 4.42352 2.49512 7.25192 2.49512 10.591C2.49512 13.9301 4.68129 16.7584 7.70039 17.723C7.92545 17.795 8.10949 17.6613 8.10949 17.4251V16.844C8.10949 16.6855 7.99007 16.5051 7.8413 16.4506C5.45233 15.5756 3.74275 13.2794 3.74275 10.591C3.74275 7.90265 5.45233 5.60633 7.8413 4.73132C7.99007 4.6768 8.10949 4.49647 8.10949 4.33803V3.7569ZM10.2928 5.60046H9.66905C9.49678 5.60046 9.35713 5.74008 9.35713 5.91234V6.87866C8.12045 7.05449 7.32099 7.87748 7.32099 8.93328C7.32099 10.2999 8.14715 10.8302 9.89094 11.0648C11.074 11.2586 11.4107 11.5134 11.4107 12.1867C11.4107 12.8597 10.8393 13.3085 10.0339 13.3085C8.94481 13.3085 8.58643 12.8319 8.45425 12.2151C8.42294 12.069 8.2978 11.9622 8.14836 11.9622H7.43685C7.25762 11.9622 7.11749 12.1236 7.14829 12.3002C7.32948 13.3384 7.99626 14.1001 9.35713 14.2848V15.2696C9.35713 15.4419 9.49678 15.5815 9.66905 15.5815H10.2928C10.4651 15.5815 10.6048 15.4419 10.6048 15.2696V14.2843C11.893 14.0796 12.7161 13.1828 12.7161 12.0744C12.7161 10.6161 11.8288 10.1265 10.1153 9.89201C8.85068 9.7084 8.60608 9.41257 8.60608 8.82102C8.60608 8.26024 9.03429 7.86233 9.86045 7.86233C10.6077 7.86233 11.0374 8.12313 11.215 8.7245C11.2546 8.85871 11.3747 8.95359 11.5146 8.95359H12.1708C12.3538 8.95359 12.4962 8.78544 12.4571 8.60664C12.2494 7.65629 11.6089 7.086 10.6048 6.9061V5.91234C10.6048 5.74008 10.4651 5.60046 10.2928 5.60046Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_34_356">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <button
                className="h-10 bg-black-alt px-4 py-2 font-mono text-sm uppercase"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setAmount(String(MAX_AMOUNT));
                }}
              >
                MAX
              </button>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button
            type="submit"
            className="h-14 w-full rounded-none font-mono text-lg font-normal tracking-wider"
            disabled={!amount || isProcessing}
            onClick={handleMint}
          >
            {isProcessing ? 'CONFIRM IN WALLET' : 'MINT'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
