import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  getFilteredRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import {
  FUNDING_RATE_DECIMALS,
  useMarketStores,
} from '@/store/use-markets-store';
import { formatBigInt, formatNumber } from '@/utils/value-format';
import { parseCommaNumStr, parsePnlChangeStr } from '../utils/format';
import {
  abbrFromSym,
  imgFromAbbr,
  symbolToBaseQuote,
} from '@/utils/token-symbol';
import { cn } from '@/utils/cn';
import { MarketRow } from './market-row';
import { useExchangeInfo } from '../api/get-exchange-info';
import { useTickerLatest } from '../api/get-ticker-latest';
import { FavoriteButton } from './favorite-button';
import { ListFilter, Search } from 'lucide-react';
import useFavorites from '@/hooks/use-favorites';
import { getMaxLeverage } from '@/features/trade/order/utils/math';

interface MarketItem {
  symbol: string;
  leverage: string;
  markPrice: bigint;
  priceChange: bigint;
  priceChangePct: bigint;
  fundingRate: bigint;
}

interface MarketsTableProps {
  isLoading: boolean;
  onRowClick: (symbol: string) => void;
}

export const MarketsTableContainer: React.FC<
  Omit<MarketsTableProps, 'isLoading'> & { isOpen: boolean }
> = (props) => {
  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo({});

  if (isExchangeInfoError) {
    return <div>Error</div>;
  }

  return <MarketsTable {...props} isLoading={isExchangeInfoPending} />;
};

// Columns handle formatting b/c raw values are needed for sorting
const MarketsTable: React.FC<MarketsTableProps & { isOpen: boolean }> = ({
  isOpen,
  isLoading,
  onRowClick,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));

  const { isFavorite } = useFavorites();

  // Data is mapped to bigint format for accurate sorting
  const data = useMemo(() => {
    return Object.entries(markets)
      .filter(([symbol, market]) =>
        abbrFromSym(symbol)
          .toLowerCase()
          .includes(debouncedSearch.toLowerCase()),
      )
      .map(([symbol, market]) => ({
        symbol,
        leverage: getMaxLeverage(market.marketSpec)?.decimal,
        markPrice: market.marketData.markPrice,
        priceChange: market.marketData.priceChange,
        priceChangePct: market.marketData.priceChangePct,
        fundingRate: market.marketData.oneHrFundingRate * 100n,
        isFavorite: isFavorite(symbol),
      }))
      .sort((a, b) => {
        if (a.isFavorite && !b.isFavorite) return -1;
        if (!a.isFavorite && b.isFavorite) return 1;
        return a.symbol.localeCompare(b.symbol);
      });
  }, [debouncedSearch, markets]);

  const columns: ColumnDef<MarketItem>[] = useMemo(
    () => [
      {
        accessorKey: 'symbol',
        header: 'Symbol',
        cell: ({ row }) => (
          <div className="flex items-center gap-3">
            <div className="mr-1.5">
              <FavoriteButton symbol={row.original.symbol} size={14} />
            </div>
            <img
              src={imgFromAbbr(abbrFromSym(row.original.symbol))}
              alt="symbol"
              className="h-5 w-5"
            />
            <span className="font-sans">
              {symbolToBaseQuote(row.original.symbol)}
            </span>
            <div className="rounded bg-[#271714] px-2">
              <span className="font-mono text-sm text-primary">
                {formatNumber(row.original.leverage, {
                  style: 'leverage',
                })}
              </span>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'markPrice',
        header: 'Mark Price',
        cell: ({ row }) => {
          const { priceDecimals } = markets[row.original.symbol].marketSpec;
          return (
            <div className={`flex items-center`}>
              <span>{formatBigInt(row.original.markPrice, priceDecimals)}</span>
            </div>
          );
        },
      },
      {
        accessorKey: 'priceChange',
        header: '24h Change',
        cell: ({ row }) => {
          const { priceDecimals } = markets[row.original.symbol].marketSpec;
          return (
            <div className={`flex items-center`}>
              <span
                className={cn({
                  'text-red': row.original.priceChange < 0,
                  'text-green': row.original.priceChange >= 0,
                })}
              >
                {`${formatBigInt(row.original.priceChange, priceDecimals, { showChange: true })} / ${formatBigInt(row.original.priceChangePct, 4n, { style: 'percent', abs: true })}`}
              </span>
            </div>
          );
        },
        sortingFn: (a, b) =>
          Number(a.original.priceChangePct) - Number(b.original.priceChangePct),
      },
      {
        accessorKey: 'fundingRate',
        header: '1h Funding',
        cell: ({ row }) => {
          return (
            <div className={`flex items-center`}>
              <span
                className={cn({
                  'text-red': row.original.fundingRate < 0,
                  'text-green': row.original.fundingRate >= 0,
                })}
              >
                {formatBigInt(row.original.fundingRate, FUNDING_RATE_DECIMALS, {
                  style: 'percent',
                  digits: Number(FUNDING_RATE_DECIMALS) - 2,
                })}
              </span>
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    // TODO: MarketItem type
    // @ts-ignore
    data,
    // @ts-ignore
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 100);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (!isOpen || !inputRef.current) return;
    inputRef.current.focus();
  }, [isOpen]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  return (
    <div className="w-full rounded-br-md border border-l-0 border-border bg-vestgrey-900">
      <div className="flex items-center justify-between gap-3 border-b border-border px-6 py-3">
        <div className="relative flex items-center gap-1.5">
          <Search size={18} className="text-font" />
          <input
            type="text"
            placeholder="SEARCH"
            autoFocus
            ref={inputRef}
            value={search}
            onChange={handleSearch}
            className="text-font w-full bg-vestgrey-900 p-2 font-mono placeholder:text-vestgrey-100 focus:outline-none"
          />
        </div>
      </div>
      <div className="no-scrollbar h-[328px] overflow-auto">
        <table className="w-full">
          <thead className="sticky top-0">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="text-left">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={cn(
                      'text-font w-1/5 cursor-pointer border-b-2 border-border bg-vestgrey-900 px-6 py-3 font-mono text-sm font-normal uppercase',
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {{
                      asc: <span className="pl-2">↑</span>,
                      desc: <span className="pl-2">↓</span>,
                    }[header.column.getIsSorted() as string] ?? null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            <tr className="h-3"></tr>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="h-72"></td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <MarketRow
                  key={row.id}
                  row={row}
                  onRowClick={(symbol) => {
                    onRowClick(symbol);
                    setSearch('');
                  }}
                />
              ))
            )}
            <tr className="h-3"></tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
