import { Outlet } from 'react-router-dom';
import { Header } from '@/components/header';
import GeofenceBanner from '../header/geofence-banner';
import useGeofenceCheck from '@/utils/geofence-check';
import ErrorBoundary from '@/app/error-boundary';

export default function RootLayout() {
  const { data: isSanctioned } = useGeofenceCheck();

  return (
    <ErrorBoundary>
      <div className="grid h-full grid-rows-[max-content_1fr] md:grid-rows-[max-content_max-content_1fr]">
        {isSanctioned ? <GeofenceBanner /> : <div />}
        <Header />
        <main className="h-full">
          <div className="hidden h-full md:block">
            <Outlet />
          </div>
          <div className="flex h-full items-center justify-center md:hidden">
            <p className="text-bold w-full max-w-xs text-center font-mono text-xl uppercase">
              Mobile trading coming soon
            </p>
          </div>
        </main>
      </div>
    </ErrorBoundary>
  );
}
